import axios from "axios"
import configExternalApi from '../configExternal'

const environment = process.env.REACT_APP_STAGE
const { apiUrlListaModulos, headerAuth, apiUrlAvailableRoles } =
  configExternalApi[environment]


const configRoutes = require("../configRoutes")
const urls = configRoutes[environment]

const LOGOB2C = require("@src/assets/images/icons/ico_b2c.svg").default
const icoMentor = require("@src/assets/images/icons/ico_mentor.svg").default
const icoB2B = require("@src/assets/images/icons/ico_b2b.svg").default

let modulesAndRoles = []
let ROLE_MAP_BY_ENVIRONMENT = {}
let ROLE_MAP_BY_ENVIRONMENT2 = {}
let MENU_BY_ROLE = []
let ICON_GROUPS = {}
let HANDLED_ROLES = []
let HANDLED_ROLES2 = []

const getModulesAndRoles = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("accessToken")
    if (!token) {
      console.error("No access token found")
      return resolve([])
    }

    const cleanToken = token.replace(/^"|"$/g, "")

    setTimeout(() => {
      axios
        .get(apiUrlListaModulos, {
          headers: {
            Authorization: `Bearer ${cleanToken}`,
            "Ocp-Apim-Subscription-Key": headerAuth
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          console.error("Error fetching modules and roles:", error)
          resolve([])
        })
    }, 0)
  })
}


 const fetchAvailableRoles = async () => {
   try {
     const token = localStorage.getItem("accessToken")
     if (!token) {
       console.error("No access token found")
       return []
     }
     const cleanToken = token.replace(/^"|"$/g, "")

     const response = await axios.get(apiUrlAvailableRoles, {
       headers: {
         Authorization: `Bearer ${cleanToken}`,
         "Ocp-Apim-Subscription-Key": headerAuth
       }
     })

     return response.data
   } catch (error) {
     console.error("Error fetching roles:", error)
     return []
   }
 }

  fetchAvailableRoles()


const getRoleColor = () => {
  const roleColor = {}
  modulesAndRoles.forEach((module) => {
    module.roles.forEach((role) => {
      roleColor[role.id] = {
        classAvatar: role.name.toLowerCase().includes("admin") ? "administracion" : role.name.toLowerCase().includes("mentor") ? "mentor" : "student",
        buttonColor: `${roleColor[role.id]?.classAvatar}-drop-down`,
        iConsColor: `${roleColor[role.id]?.classAvatar}-icons`
      }
    })
  })
  return roleColor
}

const getRoleId = (roleName) => {
  if (!ROLE_MAP_BY_ENVIRONMENT[environment]) return null
  const roleEntries = Object.entries(ROLE_MAP_BY_ENVIRONMENT[environment])
  const roleEntry = roleEntries.find(
    ([_, name]) => name.toUpperCase() === roleName.toUpperCase()
  )
  return roleEntry ? roleEntry[0] : null
}

const initializeData = async () => {
  modulesAndRoles = await getModulesAndRoles()

  ROLE_MAP_BY_ENVIRONMENT = modulesAndRoles.reduce((acc, module) => {
    module.roles.forEach((role) => {
      if (!acc[environment]) acc[environment] = {}
      acc[environment][role.id] = role.name
    })
    return acc
  }, {})

  ROLE_MAP_BY_ENVIRONMENT2 = modulesAndRoles.reduce((acc, module) => {
    module.roles.forEach((role) => {
      if (!acc[environment]) acc[environment] = {}
      acc[environment][role.uid] = role.name
    })
    return acc
  }, {})

  MENU_BY_ROLE = modulesAndRoles.map((module) => ({
    id: module.id,
    name: module.title,
    icon_url: module.icon_modulos,
    orderByRole: module.roles.reduce((acc, role) => {
      acc[role.id] = role.ordenModulo
      return acc
    }, {}),
    toRole: module.roles.map((role) => role.id),
    subModules: module.submodulos.map((submodule) => ({
      id: submodule.idSub,
      module_id: module.id,
      type: submodule.type || "default",
      name: submodule.title,
      url_dashboard: submodule.urlIframe,
      toRole: module.roles.map((role) => role.id),
      order: submodule.orden
    }))
  }))

  ICON_GROUPS = {}
  modulesAndRoles.forEach((module) => {
    module.roles.forEach((role) => {
      if (role.name.toLowerCase().includes("aprendedor")) {
        ICON_GROUPS[role.uid] = LOGOB2C
      } else if (role.name.toLowerCase().includes("mentor")) {
        ICON_GROUPS[role.uid] = icoMentor
      } else {
        ICON_GROUPS[role.uid] = icoB2B
      }
    })
  })

  HANDLED_ROLES = Object.keys(ROLE_MAP_BY_ENVIRONMENT[environment] || {})
  HANDLED_ROLES2 = Object.keys(ROLE_MAP_BY_ENVIRONMENT2[environment] || {})
}

initializeData()

export default {
  get ROLE_MAP_BY_ENVIRONMENT() {
    return ROLE_MAP_BY_ENVIRONMENT
  },
  get ROLE_MAP_BY_ENVIRONMENT2() {
    return ROLE_MAP_BY_ENVIRONMENT2
  },
  get MENU_BY_ROLE() {
    return MENU_BY_ROLE
  },
  get ICON_GROUPS() {
    return ICON_GROUPS
  },
  get HANDLED_ROLES() {
    return HANDLED_ROLES
  },
  get HANDLED_ROLES2() {
    return HANDLED_ROLES2
  },
  getRoleColor,
  getRoleId,
  initializeData 
}
