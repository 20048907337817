// ** Config URL
import config from "../../../config"
const environment = process.env.REACT_APP_STAGE
const apiUrlLogin = config[environment].apiUrlLogin

// ** Auth Endpoints
export default {
  loginEndpoint: `${apiUrlLogin}/login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${apiUrlLogin}/refreshToken`,
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
