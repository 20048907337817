// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Third Party Components
import _ from 'lodash'

// Import another api request
import { getUserMenu } from '../../../customers/store'

// ** Config URL
import config from '../../../../config'
const environment = process.env.REACT_APP_STAGE
const apiUrlRoles = config[environment].apiUrlRoles
const apiUrlSubmodules = config[environment].apiUrlSubmodules
const apiUrlUsers = config[environment].apiUrlUsers

export const getRoles = createAsyncThunk('roles/getRoles', async () => {
  try {
    const response = await axios.get(apiUrlRoles)
    return {
      data: response.data.data
    }
  } catch (e) {
    return {
      data: []
    }
  }

})

export const addRol = createAsyncThunk('roles/addRol', async (rol, { dispatch, getState }) => {
  let result = await axios.post(apiUrlRoles, rol)
  await dispatch(getRoles())
  return result
})

export const updateRol = createAsyncThunk('roles/updateRol', async (rol, { dispatch, getState }) => {
  let result = await axios.put(`${apiUrlRoles}/${rol.id}`, rol)
  await dispatch(getRoles())
  const user = JSON.parse(localStorage.getItem('userData'))
  dispatch(getUserMenu(user.id))
  return result
})

export const deleteRol = createAsyncThunk('roles/deleteRol', async (id, { dispatch, getState }) => {
  let result = await axios.delete(`${apiUrlRoles}/${id}`)
  await dispatch(getRoles())
  return result
})


export const getFieldsRoles = createAsyncThunk('appUsers/getFieldsRoles', async (id, { dispatch, getState }) => {
  try {
    let result = await axios.get(`${apiUrlRoles}/default/fields/${id}`)
    return result.data.data
  } catch (e) {
    return []
  }

})

export const getPermissions = createAsyncThunk('roles/getPermissions', async () => {
  try {

    const response = await axios.get(`${apiUrlSubmodules}/filter/byUserType`)
    // const response = await axios.get(apiUrlSubmodules)
    let data = [...response.data.data]
    let processData = data.map((item) => ({
      ...item,
      view: true
    }))
    let reportRoles = _(processData)
      .groupBy(x => x.module_name)
      .map((value, key) => ({
        id: key,
        name: key,
        view: true,
        options: value
      }))
      .value()

    let systemRoles
    const user = JSON.parse(localStorage.getItem('userData'))
    if (user.rol_name_id && user.rol_name_id === "Company Admin") {
      systemRoles = [
        {
          name: "Roles y usuarios",
          id: "Roles y usuarios",
          view: true,
          create: true,
          edit: true
        }
      ]
    } else {
      systemRoles = [
        {
          name: "Roles y usuarios",
          id: "Roles y usuarios",
          view: true,
          create: true,
          edit: true
        },
        {
          name: "Administrador de módulos",
          id: "Administrador de modulos",
          view: true,
          create: true,
          edit: true
        },
        {
          name: "Administrador de submódulos",
          id: "Administrador de submodulos",
          view: true,
          create: true,
          edit: true
        },
        {
          name: "Administración de clientes",
          id: "Administracion de clientes",
          view: true,
          create: true,
          edit: true
        }
      ]
    }

    return {
      permissions: {
        report: reportRoles,
        system: systemRoles
      }
    }


  } catch (e) {
    return {
      permissions: {
        report: [],
        system: []
      }
    }
  }

})


export const getPermissionCustomer = createAsyncThunk('roles/getPermissionCustomer', async (id) => {
  let response = await axios.get(`${apiUrlUsers}/client/permissions/${id}`)
  return response.data.data[0]
})


export const getRoleById = createAsyncThunk('roles/getRoleById', async (id) => {
  try {
    const response = await axios.get(`${apiUrlRoles}/${id}`)
    return response.data.data[0]

  } catch (e) {
    return {
      data: []
    }
  }

})

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: [],
    selectedRol: null,
    permissions: {
      report: [],
      system: []
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload.data
        state.total = action.payload.total
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions = { ...action.payload.permissions }
      })
      .addCase(getRoleById.fulfilled, (state, action) => {
        state.selectedRol = action.payload
      })
      .addCase(getPermissionCustomer.fulfilled, (state, action) => {
        state.selectedRol = action.payload
      })

  }
})


export default rolesSlice.reducer
