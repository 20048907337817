const config = {
  azure_dev: {
    apiUrlUsers: "https://sls-eus-dev-966a99-apim.azure-api.net/user",
    apiUrlSubmodules: "https://sls-eus-dev-a14975-apim.azure-api.net/submodule",
    apiUrlModules: "https://sls-eus-dev-77777c-apim.azure-api.net/module",
    apiUrlRoles: "https://sls-eus-dev-536405-apim.azure-api.net/rol",
    profileHomeURL: "https://profilehomeliderlydev.z13.web.core.windows.net"
    //profileHomeURL: "http://localhost:3001"
  },
  azure_qa: {
    apiUrlUsers: "https://sls-eus-qa-3833ed-apim.azure-api.net/user",
    apiUrlSubmodules: "https://sls-eus-qa-7e733e-apim.azure-api.net/submodule",
    apiUrlModules: "https://sls-eus-qa-3631ad-apim.azure-api.net/module",
    apiUrlRoles: "https://sls-eus-qa-ce7700-apim.azure-api.net/rol",
    profileHomeURL: "https://qa-micuenta.liderly.com"
    //profileHomeURL: "http://localhost:3001"
  },
  azure_prod: {
    apiUrlUsers: "https://sls-eus-prod-fadc10-apim.azure-api.net/user",
    apiUrlSubmodules:
      "https://sls-eus-prod-845a8a-apim.azure-api.net/submodule",
    apiUrlModules: "https://sls-eus-prod-27f250-apim.azure-api.net/module",
    apiUrlRoles: "https://sls-eus-prod-783450-apim.azure-api.net/rol",
    profileHomeURL: "https://micuenta.liderly.com"
  }
}

export default config
