const configExternalApi = {
  azure_dev: {
    apiUrlUsersExternal:
      "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customer-details",
    apiUrlCustomerImagesExternal:
      "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-profile-image",
    apiUrlAuthExternal:
      "https://apazmanagerdev01.azure-api.net/omnisys-uid/validar-token",
    urlLogout:
      "https://apiaddev.orangeforest-62ac9073.eastus.azurecontainerapps.io/logout?id=lumston",
    urlLogin:
      "https://apiaddev.orangeforest-62ac9073.eastus.azurecontainerapps.io/login?id=lumston",
    apiUrlPreferencesExternal:
      "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customer-preferences",
    apiUrlListaModulos:
      "https://apazmanagerdev01.azure-api.net/omnisys-uid/listar-modulos",
    apiUrlAvailableRoles:
      "https://apazmanagerdev01.azure-api.net/omnisys-uid/get-roles-list",
    headerAuth: "47ad372ccd7d4786b884d894da92d94a",
    headerProfileBack: "4cb4e000fde34aafb2f19e8c1b6fc252"
  },
  azure_qa: {
    apiUrlUsersExternal:
      "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customer-details",
    apiUrlCustomerImagesExternal:
      "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-profile-image",
    apiUrlAuthExternal:
      "https://apazmanagerqa01.azure-api.net/omnisys-uid-qa/validar-token",
    urlLogout:
      "https://apiadqa.wittypebble-e426d6e2.eastus.azurecontainerapps.io/logout?id=lumston",
    urlLogin:
      "https://apiadqa.wittypebble-e426d6e2.eastus.azurecontainerapps.io/login?id=lumston",
    apiUrlPreferencesExternal:
      "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customer-preferences",
    apiUrlListaModulos:
      "https://apazmanagerqa01.azure-api.net/omnisys-uid-qa/listar-modulos",
    apiUrlAvailableRoles:
      "https://apazmanagerqa01.azure-api.net/omnisys-uid-qa/get-roles-list",
    headerAuth: "c91f904676d743158373eeaae31a1081",
    headerProfileBack: "60ef4a85b063431eac459a5411a72ece"
  },
  azure_prod: {
    apiUrlUsersExternal:
      "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customer-details",
    apiUrlCustomerImagesExternal:
      "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-profile-image",
    apiUrlAuthExternal:
      "https://apazmanagerprod01.azure-api.net/omnisys-uid-prod/validar-token",
    urlLogout:
      "https://apiadprod.greensmoke-aae4d958.eastus.azurecontainerapps.io/logout?id=lumston",
    urlLogin:
      "https://apiadprod.greensmoke-aae4d958.eastus.azurecontainerapps.io/login?id=lumston",
    apiUrlListaModulos:
      "https://apazmanagerprod01.azure-api.net/omnisys-uid-prod/listar-modulos",
    apiUrlAvailableRoles:
      "https://apazmanagerprod01.azure-api.net/omnisys-uid-prod/get-roles-list",
    apiUrlPreferencesExternal:
      "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customer-preferences",
    headerAuth: "9ca124ae75aa4df1a55e199789ff854d",
    headerProfileBack: "5534d3fe823645c5b1e560e6abe41520"
  }
}

export default configExternalApi
