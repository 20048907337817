// ** Axios Imports
import axios from 'axios'

import configExternalApi from '../configExternal'
const environment = process.env.REACT_APP_STAGE
const headerProfileBack = configExternalApi[environment].headerProfileBack

export const getUserByEmail = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlUsersExternal}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data.information
    } catch (e) {
        return false
    }
}

export const getProfileImageUser = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlCustomerImagesExternal}?ownerId=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data.files[0]
    } catch (e) {
        return false
    }
}

export const getUserPreferences = async (email) => {
    try {
        let response = await axios.get(`${configExternalApi[environment].apiUrlPreferencesExternal}?customerIdentifier=${email}`, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data.information
    } catch (e) {
        console.log(e)
        return {}
    }


}

export const addUserPreferences = async (email, data) => {
    try {
        let response = await axios.put(`${configExternalApi[environment].apiUrlPreferencesExternal}?customerIdentifier=${email}`,  {information: data}, {
            headers: {
                'content-type': 'application/json',
                "Ocp-Apim-Subscription-Key": headerProfileBack
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
        return {}
    }
}


export const userRequestsExternal = {
    getUserByEmail,
    getProfileImageUser,
    getUserPreferences,
    addUserPreferences
}
