const configRoutes = {
  azure_dev: {
    urlDigitalAcademy: "https://digitalacademy.lapzo.io/",
    urlTalentoLiderly: "https://talento.liderly.com",
    urlLiderlyWeb: "https://liderly-mkp-b2c.web.app/",
    urlLiderlyMentor: "https://liderly.1mentor.io/",
    urlLiderlyDiscord: "https://www.liderly.com/discord.html",
    urlPreguntasFrecuentes: "https://liderly-3e45.mailchimpsites.com/"
  },
  azure_qa: {
    urlDigitalAcademy: "https://digitalacademy.lernitlms.com/",
    urlTalentoLiderly: "https://talento.liderly.com",
    urlLiderlyWeb: "https://liderly-mkp-b2c.web.app/",
    urlLiderlyMentor: "https://liderly.1mentor.io/",
    urlLiderlyDiscord: "https://www.liderly.com/discord.html",
    urlPreguntasFrecuentes: "https://liderly-3e45.mailchimpsites.com/"
  },
  azure_prod: {
    urlDigitalAcademy: "https://digitalacademy.lapzo.io/",
    urlTalentoLiderly: "https://talento.liderly.com",
    urlLiderlyWeb: "https://liderly-mkp-b2c.web.app/",
    urlLiderlyMentor: "https://liderly.1mentor.io/",
    urlLiderlyDiscord: "https://www.liderly.com/discord.html",
    urlPreguntasFrecuentes: "https://liderly-3e45.mailchimpsites.com/"
  }
}

export default configRoutes
