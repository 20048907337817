// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Config URL
import config from "../../../config"
const environment = process.env.REACT_APP_STAGE
const apiUrlUsers = config[environment].apiUrlUsers
const apiUrlRoles = config[environment].apiUrlRoles
import { userRequestsExternal } from "../../../services/UserRequestExternal"

import configExternalApi from "../../../configExternal"

const { headerAuth, apiUrlAvailableRoles } = configExternalApi[environment]

// ** Icons Import
import { BsRecordFill } from "react-icons/bs"

// ** Third Party Components
import _, { orderBy } from "lodash"

// ** Another requests
import { handleLogout } from "@store/authentication"
import ConfigurationRols from "../../../configs/ConfigurationRols"
// export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
//   const response = await axios.get('/api/users/list/all-data')
//   return response.data
// })

export const getUsers = createAsyncThunk(
  "appUsers/getUsers",
  async (params) => {
    try {
      const response = await axios.post(`${apiUrlUsers}/filter`, params)
      return {
        params,
        data: response.data.data.users,
        totalElements: response.data.data.total_rows,
        totalPages: response.data.data.total_pages
      }
    } catch (e) {
      return {
        data: [],
        totalElements: 0,
        totalPages: 0
      }
    }
  }
)

const addIconMenu = (type) => {
  if (type === "roles_users") {
    return <i className="fi fi-rr-user-add"></i>
  } else if (type === "submodules" || type === "modules") {
    return <i className="fi fi-rr-add-document"></i>
  } else {
    return <i className="fi fi-rs-users"></i>
  }
}

const checkIsNewDivision = (date) => {
  if (date) {
    const userDate = new Date(date)
    const threeDaysAgo = new Date()
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3)
    if (userDate > threeDaysAgo) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export const getUserRoles = () => {
  let getRolComplete = false
  let result = []

  try {
    let email = JSON.parse(localStorage.getItem("userData"))

    if (JSON.parse(localStorage.getItem("userData")).roles) {
      result = JSON.parse(localStorage.getItem("userData")).roles.map(
        (role) => {
          if (
            ConfigurationRols.ROLE_MAP_BY_ENVIRONMENT[environment][role?.uid]
          ) {
            return role?.uid
          }
        }
      )
    }
  } catch (e) {}
  return result
}

export const getSubMenu = (menuItem, roleId) => {
  return menuItem.subModules
    .filter((submoduleItem) => submoduleItem.toRole.includes(Number(roleId)))
    .sort((a, b) => a.order - b.order)
}

export const getMenutoUser = (roleId) => {
  const menu = ConfigurationRols.MENU_BY_ROLE.filter((menuItem) => menuItem.toRole.includes(Number(roleId))
  )
    .map((menuItem) => ({
      ...menuItem,
      order: menuItem.orderByRole[roleId] || Number.MAX_SAFE_INTEGER,
      subModules: getSubMenu(menuItem, Number(roleId))
    }))
    .sort((a, b) => a.order - b.order)

  return menu
}

export const getUserMenu = createAsyncThunk(
  "appUsers/getUserMenu",
  async (role, { dispatch }) => {
    try {
      const fetchAvailableRoles = async () => {
        try {
          const token = localStorage.getItem("accessToken")
          if (!token) {
            console.error("No access token found")
            return { B2B: [], B2C: [] }
          }
          const cleanToken = token.replace(/^"|"$/g, "")

          const response = await axios.get(apiUrlAvailableRoles, {
            headers: {
              Authorization: `Bearer ${cleanToken}`,
              "Ocp-Apim-Subscription-Key": headerAuth
            }
          })

          return response.data || { B2B: [], B2C: [] }
        } catch (error) {
          console.error("Error fetching roles:", error)
          return { B2B: [], B2C: [] }
        }
      }

      const allRoles = await fetchAvailableRoles()
      const allRolesB2C = allRoles.B2C

      const roles = JSON.parse(localStorage.getItem("userData")).roles

      const rolesExist = allRolesB2C.filter((role) => roles.some((roleUser) => roleUser.name === role.name)
      )

      const actualRole = localStorage.getItem("selectedRoleB2C")

      const userData = JSON.parse(localStorage.getItem("userData"))

      if (!userData || !userData.roles || userData.roles.length === 0) {
        console.error("No hay datos de usuario o roles disponibles")
        return []
      }

      const roleToUse = actualRole || userData.roles[0].uid

      const selectedRole = userData.roles.find(
        (role) => role.uid === roleToUse
      )

      if (!selectedRole) {
        console.error(
          "El rol seleccionado no se encuentra en la lista de roles del usuario"
        )
        return []
      }

      const nameToSend = selectedRole.name
      const roleToSend = ConfigurationRols.getRoleId(nameToSend)
      console.log("nameToSend", nameToSend)

      if (!roleToSend) {
        console.error("No se pudo obtener un ID de rol válido")
        window.location.reload()
        return []
      }

      if (roleToUse !== actualRole) {
        localStorage.setItem("selectedRoleB2C", roleToUse)
      }

      let arrayMenuReports = []

      // Siempre agregamos el encabezado "MÓDULOS"
      arrayMenuReports.push({ header: "MÓDULOS" })

      if (rolesExist.length === 0) {
        // Si rolesExist está vacío, solo mostramos el menú de bienvenida
        arrayMenuReports.push({
          id: "/home",
          title: "Bienvenida",
          icon: <i className="fi fi-rr-star"></i>,
          children: [
            {
              id: "/home",
              title: "¡Hola, Liderly!",
              icon: <BsRecordFill size={12} />,
              navLink: `/home`
            }
          ],
          navLink: null
        })
      } else if (nameToSend === "Visualizador admin") {
        arrayMenuReports.push({
          id: "/home",
          title: "Inicio",
          icon: <i className="fi fi-rr-home"></i>,
          navLink: `/home`
        })
      } else {
        // Si rolesExist no está vacío, continuamos con la lógica original
        const modules = getMenutoUser(roleToSend)

        if (modules && modules.length) {
          modules.forEach(function (item) {
            let iconDivision = <i className={item.icon_url}></i>

            if (
              item.name.trim().toLowerCase() === "inicio" &&
              (!item.subModules || item.subModules.length === 0)
            ) {
              // Si es el módulo "Inicio" sin submodulos, lo tratamos como un botón
              arrayMenuReports.push({
                id: "/home",
                title: "Inicio",
                icon: <i className="fi fi-rr-home"></i>,
                navLink: `/home`
              })
            } else {
              const menuItem = {
                id: item.id,
                title: item.name,
                isNew: checkIsNewDivision(item.create_date),
                icon: iconDivision,
                children: [],
                navLink:
                  item.subModules && item.subModules.length ? null : `/${item.id}/`,
                type: item.type
              }

              if (item.subModules && item.subModules.length) {
                item.subModules.forEach(function (option) {
                  let processName = item.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                  processName = processName.replace(/\s/g, "")
                  menuItem.children.push({
                    id: option.id,
                    title: option.name,
                    icon: <BsRecordFill size={12} />,
                    url_dashboard: option.url_dashboard,
                    navLink: `/divisions/${processName}/${option.id}`
                  })
                })

                const misRetosOption = menuItem.children.find(
                  (option) => option.title === "Mis retos"
                )
                if (misRetosOption) {
                  menuItem.children = menuItem.children.filter(
                    (option) => option.title !== "Mis retos"
                  )
                  menuItem.children.push({
                    id: 39,
                    title: "Mis retos",
                    icon: <BsRecordFill size={12} />,
                    url_dashboard: "/home",
                    navLink: `/challenge`
                  })
                }
              } else {
                menuItem.children.push({
                  icon: <BsRecordFill size={12} className="icon-hideMenu" />,
                  title: "Sin submódulos",
                  disabled: true
                })
              }

              arrayMenuReports.push(menuItem)
            }
          })
        } else {
          // Si no hay módulos, mostramos el menú de bienvenida
          arrayMenuReports.push({
            id: "/home",
            title: "Bienvenida",
            icon: <i className="fi fi-rr-star"></i>,
            children: [
              {
                id: "/home",
                title: "¡Hola, Liderly!",
                icon: <BsRecordFill size={12} />,
                navLink: `/home`
              }
            ],
            navLink: null
          })
        }
      }

      return arrayMenuReports
    } catch (e) {
      console.error("Error in getUserMenu:", e)
      return []
    }
  }
)
export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch, getState }) => {
    let result = await axios.post(apiUrlUsers, user)
    await dispatch(getUsers(getState().users.params))
    return result
  }
)

export const updateUser = createAsyncThunk(
  "appUsers/updateUser",
  async (params, { dispatch, getState }) => {
    let result = await axios.put(`${apiUrlUsers}/${params.id}`, params.user)
    await dispatch(getUsers(getState().users.params))
    const userLocal = JSON.parse(localStorage.getItem("userData"))
    dispatch(getUserMenu(userLocal.id))
    return result
  }
)

export const updateUserProfile = createAsyncThunk(
  "appUsers/updateUserProfile",
  async (user, { dispatch, getState }) => {
    let result = await userRequestsExternal.addUserInformation(
      user.email,
      user
    )
    // let result = await userRequestsExternal.addUserInformation("yairvmtz@gmail.com", user)
    return result
  }
)

export const activeUser = createAsyncThunk(
  "appUsers/activeUser",
  async (id, { dispatch, getState }) => {
    let result = await axios.patch(`${apiUrlUsers}/status/${id}?status=1`)
    await dispatch(getUsers(getState().users.params))
    return result
  }
)

export const deactiveUser = createAsyncThunk(
  "appUsers/deactiveUser",
  async (id, { dispatch, getState }) => {
    let result = await axios.patch(`${apiUrlUsers}/status/${id}?status=0`)
    await dispatch(getUsers(getState().users.params))
    return result
  }
)

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    let result = await axios.delete(`${apiUrlUsers}/${id}`)
    await dispatch(getUsers(getState().users.params))
    return result
  }
)

export const getUserById = createAsyncThunk(
  "appUsers/getUserById",
  async (id) => {
    try {
      const response = await axios.get(`${apiUrlUsers}/${id}`)
      return response.data.data[0]
    } catch (e) {
      return {
        data: []
      }
    }
  }
)

export const getUserByEmail = createAsyncThunk(
  "appUsers/getUserByEmail",
  async (email) => {
    try {
      let params = {
        search: email,
        page: 0,
        limit: 1
      }

      const response = await axios.post(`${apiUrlUsers}/filter`, params)
      return response.data.data.users[0]
    } catch (e) {
      return {}
    }
  }
)

export const getRolesForUsers = createAsyncThunk(
  "roles/getRolesForUsers",
  async () => {
    try {
      const response = await axios.get(apiUrlRoles)
      response.data.data.forEach(function (item) {
        item.value = item.id
        item.label = item.name
      })

      return response.data.data
    } catch (e) {
      return {
        data: []
      }
    }
  }
)

export const getRolesFromFilter = createAsyncThunk(
  "roles/getRolesFromFilter",
  async () => {
    try {
      const response = await axios.get(apiUrlRoles)
      response.data.data.forEach(function (item) {
        item.value = item.id
        item.label = item.name
      })

      response.data.data.unshift({ value: 0, label: "Seleccionar rol" })
      return response.data.data
    } catch (e) {
      return {
        data: []
      }
    }
  }
)

export const getCustomers = createAsyncThunk(
  "appUsers/getCustomers",
  async (params) => {
    try {
      const response = await axios.post(`${apiUrlUsers}/client/filter`, params)
      return {
        params,
        data: response.data.data.clients,
        totalElements: response.data.data.total_rows,
        totalPages: response.data.data.total_pages
      }
    } catch (e) {
      return {
        data: [],
        totalElements: 0,
        totalPages: 0
      }
    }
  }
)

export const addCustomer = createAsyncThunk(
  "appUsers/addCustomer",
  async (user, { dispatch, getState }) => {
    let result = await axios.post(`${apiUrlUsers}/client`, user)
    await dispatch(getCustomers(getState().users.params))
    return result
  }
)

export const getCustomerById = createAsyncThunk(
  "appUsers/getCustomerById",
  async (id) => {
    try {
      const response = await axios.get(`${apiUrlUsers}/client/${id}`)
      return response.data.data[0]
    } catch (e) {
      return {
        data: []
      }
    }
  }
)

export const updateCustomer = createAsyncThunk(
  "appUsers/updateCustomer",
  async (params, { dispatch, getState }) => {
    let result = await axios.put(
      `${apiUrlUsers}/client/${params.id}`,
      params.customer
    )
    await dispatch(getCustomers(getState().users.params))
    return result
  }
)

export const activeCustomer = createAsyncThunk(
  "appUsers/activeCustomer",
  async (id, { dispatch, getState }) => {
    let result = await axios.patch(
      `${apiUrlUsers}/client/status/${id}?status=1`
    )
    await dispatch(getCustomers(getState().users.params))
    return result
  }
)

export const deactiveCustomer = createAsyncThunk(
  "appUsers/deactiveCustomer",
  async (id, { dispatch, getState }) => {
    let result = await axios.patch(
      `${apiUrlUsers}/client/status/${id}?status=0`
    )
    await dispatch(getCustomers(getState().users.params))
    return result
  }
)

export const addPermissionsCustomer = createAsyncThunk(
  "appUsers/addPermissionsCustomer",
  async (params, { dispatch, getState }) => {
    let result = await axios.put(
      `${apiUrlUsers}/client/permissions/${params.id}`,
      params.permissions
    )
    await dispatch(getCustomers(getState().users.params))
    return result
  }
)

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    users: null,
    totalElements: 0,
    totalPages: 0,
    params: {},
    allData: [],
    selectedUser: null,
    totalUsers: 0,
    activeUsers: 0,
    inactiveUsers: 0,
    roles: [],
    rolesFromFilter: [],
    menu: [],
    customers: null,
    openChatbot: false,
    menuHover: false,
    updateInfo: false
  },
  reducers: {
    handleOpenChatbot: (state, action) => {
      state.openChatbot = action.payload
    },
    handleMenuHover: (state, action) => {
      state.menuHover = action.payload
    },
    handleUpdateUserInfo: (state, action) => {
      state.updateInfo = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getAllData.fulfilled, (state, action) => {
      //   state.allData = action.payload
      // })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data
        state.params = action.payload.params
        state.totalElements = action.payload.totalElements
        state.totalPages = action.payload.totalPages
      })
      .addCase(getRolesForUsers.fulfilled, (state, action) => {
        state.roles = action.payload
      })
      .addCase(getRolesFromFilter.fulfilled, (state, action) => {
        state.rolesFromFilter = action.payload
      })
      .addCase(getUserMenu.fulfilled, (state, action) => {
        state.menu = action.payload
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.data
        state.params = action.payload.params
        state.totalElements = action.payload.totalElements
        state.totalPages = action.payload.totalPages
      })
  }
})

export const { handleOpenChatbot, handleMenuHover, handleUpdateUserInfo } =
  appUsersSlice.actions

export default appUsersSlice.reducer
