// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Config URL
import config from '../../../config'
const environment = process.env.REACT_APP_STAGE
const apiUrlUsers = config[environment].apiUrlUsers


export const getNotifications = createAsyncThunk('appTodo/getTasks', async params => {
  try {
    const response = await axios.get(`${apiUrlUsers}/notifications`, { params })
    return {
      params,
      data: response.data,
      totalElements: response.totalElements,
      totalPages: response.totalPages
    }
  } catch (e) {
    let defaultData = [
      {
        id: 1,
        title: '¡Bienvenidx a Liderly!',
        dueDate: '00:57PM',
        completeDate: "",
        description: "Bienvenidx al portal de Liderly donde podrás acceder a nuestra oferta para apoyarte a alcanzar tus metas. ¡Nos encanta que formes parte de la comunidad Liderly!",
        isCompleted: false,
        created_at: new Date()
      }
    ]
    return {
      params,
      data: defaultData,
      totalElements: 1,
      totalPages: 1
    }
  }

})


export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: null,
    params: {},
    totalElements: 0,
    totalPages: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.data
      state.params = action.payload.params
      state.totalElements = action.payload.totalElements
      state.totalPages = action.payload.totalPages
    })
  }
})

export default notificationsSlice.reducer
