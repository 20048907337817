// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import roles from '@src/views/apps/roles-permissions/store'
import users from '@src/views/customers/store'
import notifications from "@src/views/notifications/store"

const rootReducer = {
  auth,
  navbar,
  layout,
  roles,
  users,
  notifications
}

export default rootReducer
